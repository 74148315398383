import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { useState } from "react";
import { SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import Store from "../../index";
import Checkbox from "@material-ui/core/Checkbox";
export function PrinterSettingModal(props) {
  const [receiptMetadata, updateReceiptMetadata] = useState(
    props?.receipt_metadata && Object.keys(props?.receipt_metadata).length > 0
      ? props.receipt_metadata
      : {
          printerUrlAddress: props?.receipt_metadata?.printerUrlAddress,
          posprinterPaperWidth: props?.receipt_metadata?.posprinterPaperWidth,
          salesprinterPaperWidth:props?.receipt_metadata?.salesprinterPaperWidth,
          printerreciepttoken: props?.receipt_metadata?.printerreciepttoken,
        }
  );
  const [isLoading, updateIsLoading] = useState(false);
  const [isLogoUploading, updateIsLogoUploading] = useState(false);
  const [checked, setChecked] = useState(receiptMetadata?.printerreciepttoken);

  // console.log("here is data:", receiptMetadata);

  const savePrinterSetting = () => {
    console.log("receiptMetadata", receiptMetadata);
    // Create a new object that combines the existing receiptMetadata with the checked state
    saveReceiptMetadata();
    // Store.dispatch({
    //   type: "PRINTER_SETTINGS",
    //   printer_settings: { ...receiptMetadata, printerreciepttoken: checked },
    // });
    // props?.close();
  };

  const updateReceiptMetadataSate = (key, value) => {
    // Use functional update to ensure state is updated correctly
    updateReceiptMetadata((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    return newChecked; // This will return true or false based on the checkbox state
  };

  const saveReceiptMetadata = () => {
  // console.log("receiptMetadata", receiptMetadata);

  //   Store.dispatch({
  //     type: "RECEIPT_METADATA",
  //     receipt_metadata: receiptMetadata,
  //   });

  // if (
  //   Object.values(receiptMetadata).filter((x) => x === null || x === "")
  //     .length > 0
  // ) {
  //   helpers.toastNotify("error", "All fields must be filled properly.");
  //   return;
  // }
  //  console.log(
  //    Object.values(receiptMetadata).filter((x) => x === null || x === "")
  //  );
  //  updateIsLoading(true);


var createObject={
  "printerPaperWidth": receiptMetadata.posprinterPaperWidth,
  "printerType": "DotMatrix",
  // "companyId": "localStore1",
  "printerPaperSalesWidth": receiptMetadata.salesprinterPaperWidth,
  "token":  checked,
  "printerUrl": receiptMetadata.printerUrlAddress
}
   fetch(props.basePath + "/companyInfo/receiptMetaDataPrinter", {
     method: "PUT",
     headers: {
       "Content-Type": "application/json",
       Authorization: "Bearer my-token",
     },
     body: JSON.stringify(createObject),
   })
     .then((response) => response.json())
     .then((data) => {
       console.log("receipt Response", data);
      //  Store.dispatch({ type: "RECEIPT_METADATA", receipt_metadata: data });
       
      Store.dispatch({
        type: "PRINTER_SETTINGS",
        printer_settings: { ...receiptMetadata, printerreciepttoken: checked },
      });
      props?.close();
      
      
      helpers.toastNotify("success", "Receipt Metadata Saved Successfully.");
       updateIsLoading(false);
     })
     .catch((err) => {
       updateIsLoading(false);
       console.log(err);
       helpers.toastNotify("error", "Something went wrong while saving.");
     });
  };

  const postImages = async (images) => {
    return new Promise((resolve, reject) => {
      images.forEach(async (image) => {
        let formData = new FormData();
        formData.append("file", image);
        let urll = `https://s3.hulmsolutions.com/upload`;
        fetch(urll, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  };

  // const updateReceiptMetadataSate = (key, value) => {
  //   var receiptMetadataDeepCopy = JSON.parse(JSON.stringify(receiptMetadata));
  //   receiptMetadataDeepCopy[key] = value;
  //   updateReceiptMetadata(receiptMetadataDeepCopy);
  // };

  console.log("here is checked:", checked);

  return (
    <Dialog
      fullWidth={true}
      open={props?.open}
      TransitionComponent={props?.transition}
      keepMounted
      onClose={props?.close}
    >
      <DialogTitle>{"Printer Setting"}</DialogTitle>
      {!isLoading && (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                {/* <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Business Name"
                  name="businessName"
                  value={receiptMetadata?.businessName}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "businessName",
                      event.target.value
                    );
                  }}
                ></TextField> */}
                {/* <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Business Address"
                  name="businessAddress"
                  value={receiptMetadata?.businessAddress}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "businessAddress",
                      event.target.value
                    );
                  }}
                ></TextField> */}
                {/* <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Phone Number"
                  name="phoneNumber"
                  value={receiptMetadata?.phoneNumber}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "phoneNumber",
                      event.target.value
                    );
                  }}
                ></TextField> */}
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Printer URL Address"
                  placeholder="Default Printer URL Address http://localhost:3002/printserver"
                  name="printerUrlAddress"
                  value={receiptMetadata?.printerUrlAddress}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "printerUrlAddress",
                      event.target.value
                    );
                  }}
                ></TextField>

                <FormControl
                  fullWidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  <InputLabel id="printer-width-label">
                    {/* Printer Paper Width */}
                    POS Printer Paper Reciept
                  </InputLabel>
                  <Select
                    labelId="printer-width-label"
                    label="POS Printer Paper Width"
                    value={receiptMetadata?.posprinterPaperWidth}
                    onChange={(event) => {
                      updateReceiptMetadataSate(
                        "posprinterPaperWidth",
                        event.target.value
                      );
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="80mm">80mm</MenuItem>
                    <MenuItem value="78mm">78mm</MenuItem>
                    <MenuItem value="76mm">76mm</MenuItem>
                    <MenuItem value="58mm">58mm</MenuItem>
                    <MenuItem value="57mm">57mm</MenuItem>
                    <MenuItem value="44mm">44mm</MenuItem>
                    <MenuItem value="A4">A4</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  <InputLabel id="printer-width-label">
                    Sales Printer Paper Reciept
                  </InputLabel>
                  <Select
                    labelId="printer-width-label"
                    label="Sales Printer Paper Width"
                    value={receiptMetadata?.salesprinterPaperWidth}
                    onChange={(event) => {
                      updateReceiptMetadataSate(
                        "salesprinterPaperWidth",
                        event.target.value
                      );
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="80mm">80mm</MenuItem>
                    <MenuItem value="78mm">78mm</MenuItem>
                    <MenuItem value="76mm">76mm</MenuItem>
                    <MenuItem value="58mm">58mm</MenuItem>
                    <MenuItem value="57mm">57mm</MenuItem>
                    <MenuItem value="44mm">44mm</MenuItem>
                    <MenuItem value="A4">A4</MenuItem>
                  </Select>
                </FormControl>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                    // inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <span>
                    <p style={{ display: "inline" }}>Token Reciept</p>
                  </span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <a
              style={{ marginRight: "auto", marginBottom: 2, marginLeft: 20 }}
              href="/HulmSolutionsPrintingService_v1.3.6.exe"
              target="_blank"
              className="text-success"
            >
              Download Printer App
            </a>

            <Button onClick={props?.close} color="secondary">
              Close
            </Button>
            <Button onClick={savePrinterSetting} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {isLoading && (
        <div
          style={{
            margin: 10,
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <CircularProgress
            size={40}
            thickness={2.5}
            style={{ color: SKY_BLUE }}
          />
        </div>
      )}
    </Dialog>
  );
}
