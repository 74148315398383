import React, { Component, useRef, useState } from "react";
import GlobalConfig from "../../Config";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid } from "@mui/x-data-grid";
import Box from "@material-ui/core/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import { Typography } from "@mui/material";
import { grey } from "@material-ui/core/colors";
import { QrCode } from "@mui/icons-material";
import helpers from "../../UtilitiesFunctions";
import { Modal } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = (theme) => ({
  dataGrid: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    //color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    width: "640px",
  },
});

class PurchaseProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      previousProducts: [],
      selectedRows: [],
      loader: true,
      variant: 0,
      btnToggle: true,
      addBtn: false,
      // addBtn: true,
      query: "",
      modelRowsIds: [],
      newSelectedRows: [],
      rows: [],
      productdetailrow: "",
      productids: "",
      quantityvalue: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  populateRows = (products) => {
    const rows = [];
    console.log("products", products);
    for (var i = 0; i < products.length; i++) {
      console.log("obj[i])", products[i]);
      if (products[i]?.varients?.length > 0) {
        console.log("PUSHING VARIENT");
        const filteredList = products[i]?.varients?.filter((item) => {
          if (item?.status === "Active") return item;
        });
        filteredList?.map((varient, index) => {
          var specificationName = " (";
          varient?.productSpecCharacteristics?.map((productspec, index) => {
            //specificationName += " -";
            productspec?.productSpecCharValues?.map(
              (productSpecCharValues, index) => {
                console.log(
                  "here is a product specificationvalues",
                  productSpecCharValues
                );
                specificationName +=
                  productSpecCharValues?.value +
                  (index + 1 == productspec?.productSpecCharValues?.length &&
                  productspec?.productSpecCharValues?.length > 1
                    ? ", "
                    : "");
                console.log("here is varient product", specificationName);
              }
            );
            specificationName +=
              index + 1 != varient?.productSpecCharacteristics?.length &&
              varient?.productSpecCharacteristics?.length > 1
                ? " - "
                : "";
          });
          specificationName += ")";
          rows.push({
            id: varient.POID,
            productId: varient.POID,
            productName: varient.name + specificationName,
            price: varient.price,
            quantity: 1,
            fulfilled: false,
            tax: varient.taxRate,
            unitOfMeasures: varient.unitsOfMeasures,
            action: "add",
            costPrice: varient.costPrice,
          });
        });
      } else {
        rows.push({
          id: products[i].POID,
          productId: products[i].POID,
          productName: products[i].name,
          price: products[i].price,
          quantity: 1,
          fulfilled: false,
          tax: products[i].taxRate,
          unitOfMeasures: products[i].unitsOfMeasures,
          action: "add",
          costPrice: products[i].costPrice,
        });
      }
    }

    return rows;
  };

  componentDidMount() {
    console.log(
      "here is purchase orders from table",
      this.props.g_purchaseorders
    );

    if (this.props.g_active_products?.length === 0) {
      let url = this.props.global_config.basePath + "/products?status=Active";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then(async (result) => {
          let data = await result?.json();

          if (data.length) {
            this.setState({
              products: data?.filter((product) => product.quantity > 0),
              rows: this.populateRows(
                data?.filter((product) => product.quantity > 0)
              ),
              previousProducts: data?.filter((product) => product.quantity > 0),
              loader: false,
            });

            Store.dispatch({
              type: "GET_ACTIVE_PRODUCTS",
              active_products: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            products: [],
            previousProducts: [],
            loader: false,
          });
        });
    } else {
      this.setState({
        products: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        rows: this.populateRows(
          this.props.g_active_products?.filter(
            (product) => product.quantity > 0
          )
        ),
        previousProducts: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        loader: false,
      });
    }
  }

  purchaseorderstosync() {
    helpers.toastNotify("success", "Loading updated GRN Details...");
    fetch(
      `${this.props.global_config.basePath}/PurchaseOrderManagement/purchaseOrdersWithoutDetails`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.length > 0) {
          this.setState({
            purchaseorders: data,
            loader: false,
          });
          Store.dispatch({ type: "GET_PURCHASE_ORDERS", purchaseorders: data });
          this.searchProductBackend(this.state.query);
          helpers.toastNotify("success", "GRN Loaded Successfully!");
        } else {
          this.setState({
            loader: false,
          });
          Store.dispatch({ type: "GET_PURCHASE_ORDERS", purchaseorders: [] });
          helpers.toastNotify("error", "No orders Available");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
        helpers.toastNotify("error", "Error Fetching Orders!");
      });
  }
  scanBarCodeAndAddToCart = (barCode) => {
    const foundProducts = this.state?.products?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );

    const foundProductIds = foundProducts.map((product) => product.id);

    console.log(this.state?.rows, { foundProducts: foundProducts });
    if (foundProducts.length === 1) {
      this.setState({
        modelRowsIds: [
          ...new Set([...foundProductIds, ...this.state.modelRowsIds]),
        ],
      });
    } else if (foundProducts.length > 1) {
      helpers.toastNotify(
        "error",
        "More than one products with same barcode found. Consider selecting manually."
      );
    } else if (foundProducts.length === 0) {
      helpers.toastNotify("error", "No products found with given barcode.");
    }
  };

  handleSubmit = async (event) => {
    this.setState({
      loader: true,
    });
    // Loop through each product and create purchaseOrderItems format
    if (this.state.productids === "") {
      helpers.toastNotify("error", "Enter Valid P.O");
      return 0;
    }

    const dataObj = [
      {
        id: this.state.productids[0].id,
        quantity: this.state.quantityvalue,
      },
    ];

    let urll =
      this.props.global_config.basePath +
      "/PurchaseOrderManagement/applyPurchaseOrderItemsQtyWise";
    fetch(urll, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(dataObj),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log("from qt wise", data);
        this.purchaseorderstosync();

        helpers.toastNotify("success", "Goods Received Successfully!");
      })
      .catch((error) => {
        helpers.toastNotify("error", "something went wrong");
      });

    // this.props.handleClose("A");
  };

  //CODE BY SAM
  newHandleSubmit = () => {
    let oldSelectedProducts = this.state.selectedRows; //products which are added after pressing add
    let newSelectedProducts = this.state.newSelectedRows;

    oldSelectedProducts = oldSelectedProducts.concat(newSelectedProducts);

    console.log(oldSelectedProducts);

    this.props.updateParent(oldSelectedProducts, "dbItem", this.state.products);

    this.props.handleClose("A");
  };

  onSelectRow = (rowData) => {
    console.log("rowData", rowData);
    if (this.state.newSelectedRows.length) {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      } else {
        this.state.newSelectedRows = this.state.newSelectedRows.filter(
          (existingData) => existingData.id != rowData.data.id
        );
      }
    } else {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      }
    }

    console.log(this.state.newSelectedRows);
  };

  onSelectProduct = (selectedProductRowsId) => {
    console.log({ selectedProductRowsId: selectedProductRowsId });
    this.setState({
      modelRowsIds: selectedProductRowsId,
    });
  };

  onDelete = (e) => {
    const id = e.productId;
    this.setState({
      selectedRows: this.state.selectedRows.filter(
        (item) => item.productId !== id
      ),
    });
  };
  handleOnSearch = (e) => {
    console.log("in handle change");
    console.log(e.target.value);
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousProducts,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_active_products,
        e.target.value
      );

      console.log("here is filtered list", filteredList);

      this.setState({
        backdrop: false,
        products: filteredList,
        rows: this.populateRows(filteredList),
      });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  cancelSearch = () => {
    this.setState({ query: "", products: this.state.previousProducts });
  };
  handleSearchByName = (key, value) => {
    const filteredList = this.dynamicSearch(this.props.g_inventoryItems, value);
    if (filteredList.length !== 0) {
      this.setState({
        products: filteredList,
      });
    }
  };
  searchProductBackend = (value) => {
    if (value === "") {
      helpers.toastNotify("error", "enter valid P.O");
      return 0;
    }

    if (this.props.g_purchaseorders.length === 0) {
      helpers.toastNotify("warning", "load the purchase orders");
      return 0;
    }

    const purchasproducts = this.props.g_purchaseorders.filter(
      (purchaseorder) => purchaseorder.POID === value
    );

    if (purchasproducts.length === 0) {
      helpers.toastNotify("error", "No purchase orders found");
      return 0;
    }

    // let itemDetails = purchasproducts[0].purchaseOrderItems.map((item) => {
    //   let product = this.props.g_active_products.find(
    //     (prod) => prod.id === item.Product_Id
    //   );
    //   return {
    //     purchaseOrderItemId: item.id,
    //     productName: product ? product.name : "Product Not Found",
    //     productId: item.POID,
    //     status: item.status,
    //     quantity: item.quantity
    //   };
    // });
    let itemDetails = Object.values(
      purchasproducts[0].purchaseOrderItems.reduce((acc, item) => {
        // Find the product in the active products list
        let product = this.props.g_active_products.find(
          (prod) => prod.id === item.Product_Id
        );

        // Only proceed if the product exists
        if (product) {
          // If the product hasn't been added to the accumulator or the current item has a higher POID
          if (!acc[item.Product_Id] || item.POID > acc[item.Product_Id].POID) {
            acc[item.Product_Id] = item; // Update to the item with the higher POID
          }
        }
        return acc;
      }, {})
    );

    itemDetails = itemDetails.map((item) => {
      let product = this.props.g_active_products.find(
        (prod) => prod.id === item.Product_Id
      );

      // Create the result object with the necessary fields
      return {
        purchaseOrderItemId: item.id,
        productName: product ? product.name : "Product Not Found",
        productId: item.POID,
        status: item.status,
        quantity: item.quantity,
      };
    });

    console.log("here is item details", itemDetails);

    this.setState({ productdetailrow: itemDetails });
  };
  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 20, editable: true },
      {
        field: "productname",
        headerName: "Product Name",
        width: 150,
        editable: true,
      },
      { field: "qty", headerName: "PO QTY", width: 80, editable: true },

      {
        headerName: "Received QTY",
        field: "actions",
        width: 220,
        // flex: 1,
        headerAlign: "center",
        align: "center",
        // height:500,
        renderCell: (params, index) => {
          var userdata = params?.row?.actions;
          var myuserData = params?.row;
          // const onAdd = (value) => {
          //   this.setState({ quantityvalue: value }, () => {
          //     this.setState({
          //       dialogTitle: "ADD INVENTORY",
          //       dialogMessage:
          //         "Provide Reason Why You want to add in inventory? ",
          //       transactionType: "add",
          //       userdata: userdata,
          //     });
          //     // this.openDialogBox();
          //   });
          // };

          const onUpdate = (value) => {
            var userdata = params?.row;

            console.log("here is userdata from update cell", userdata);

            this.setState({ quantityvalue: value }, () => {
              // this.setState({
              //   dialogTitle: "UPDATE INVENTORY",
              //   dialogMessage:
              //     "Provide Reason Why You want to update in inventory? ",
              //   transactionType: "adjustment",
              //   userdata: userdata,
              // });
              // this.openDialogBox();

              if (userdata.status === "applied") {
                helpers.toastNotify("warning", "Quantity already applied ");
                return;
              }
              if (this.state.quantityvalue === 0) {
                helpers.toastNotify("warning", "Received can not be ZERO");
                return;
              }

              if (this.state.quantityvalue > userdata.qty) {
                helpers.toastNotify(
                  "warning",
                  "Received can not be greater than PO QTY"
                );
                return;
              }

              if (this.state.quantityvalue) {
                this.handleSubmit();
              } else {
                helpers.toastNotify(
                  "warning",
                  "Please enter value in quantity field!"
                );
              }
            });
          };
          return (
            <InventoryComponent
              // onadd={onAdd.bind(this)}
              onupdate={onUpdate.bind(this)}
              myuserData={myuserData}
              itemDetails={this.props?.g_purchaseorders}
              po_id={this.state.query}
            ></InventoryComponent>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: 80,
        editable: true,
      },
    ];

    var obj = this.state.productdetailrow;
    var rows = [];
    for (var i = 0; i < obj.length; i++) {
      rows[i] = {
        id: obj[i]?.productId || i,

        productname: obj[i].productName,
        status: obj[i].status,
        qty: obj[i].quantity,
        // discountamount: obj[i].discountAmount,
        // payable: obj[i].payableAmount,
        // payment: obj[i].paid ? "Paid" : "Unpaid",
        // fulfillment: obj[i].fulfilled ? "Fulfilled" : "Unfulfilled",
        // items: obj[i].lineItems.length + " item/s",
        // deliverymethod: "COD",
      };
    }

    console.log(
      "here is both data:",
      this.state.quantityvalue,
      this.state.productids
    );

    return (
      <div>
        <Box style={{ margin: "10px" }}>
          <TextField
            fullWidth={true}
            // defaultValue={this.state.query}
            name="query"
            onChange={(e) => this.handleOnSearch(e)}
            id="outlined-basic"
            placeholder="Search PO"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ cursor: "pointer" }}>
                  {/* <IconButton> */}
                  <SearchTwoToneIcon
                    onClick={() => this.searchProductBackend(this.state.query)}
                  />

                  {/* </IconButton> */}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <div style={{ width: "100%" }}>
          {this.state.productdetailrow?.length > 0 && (
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                [`& .MuiDataGrid-cell .MuiDataGrid-cellContent`]: {
                  whiteSpace: "normal",
                  overflow: "wrap",
                },
              }}
              style={{
                position: "relative",
                marginBottom: "40px",
                marginTop: "20px",
              }}
              onSelectionModelChange={(selectedRowArrIndex) => {
                var productids = [];
                console.log("selected array index here:", selectedRowArrIndex);
                let convertedArray = selectedRowArrIndex.map((item) => {
                  return productids.push({ id: item });
                });

                console.log("here is all product ids", productids);

                this.setState({ productids: productids });

                this.onSelectProduct(selectedRowArrIndex);
              }}
              // checkboxSelection
              autoHeight={true}
              loading={this.state.loader}
              scrollbarSize={1}
              rows={rows}
              columns={columns}
              selectionModel={this.state.modelRowsIds}
              editMode={"row"}
            />
          )}

          {this.state.loader && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                zIndex: 10,
                cursor: "not-allowed",
              }}
            />
          )}

          {this.state.productdetailrow?.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "200px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <h5>No GRN found</h5>
            </div>
          )}
        </div>
        <Box
          display="flex"
          justifyContent={"flex-end"}
          p={1}
          style={{
            background: "white",
            position: "absolute",
            width: "100%",
            bottom: "0",
            left: "0",
          }}
        >
          {/* <Box p={1}>
            <Link to="/ProductForm">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                style={{}}
              >
                Create a new product
              </Button>
            </Link>
          </Box> */}
          {/* <Box p={1}>
            <Button
              style={{ backgroundColor: SKY_BLUE }}
              variant="outlined"
              onClick={this.handleSubmit}
              disabled={this.state.addBtn}
            >
              Submit
            </Button>
          </Box> */}
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_active_products: state.productsState.active_products,
    global_config: state.globalState,
    g_purchaseorders: state.purchaseOrderState.purchaseorders,
  };
};
export default connect(mapStateToProps, null)(PurchaseProductsTable);
const InventoryComponent = (props) => {
  {
    const [open, setOpen] = useState(false);

    const [purchaseOrderItems, setpurchaseOrderItems] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const ref = useRef();
    const getGrn = (product_id, all_pos, po_id) => {
      console.log("particular product id detail", product_id.id);
      console.log("all pos", props?.itemDetails);
      console.log("main po id", props?.po_id);

      // Find the purchase order object that has the matching POID at the top level
      const poid_object = all_pos.find((po) => po.POID === po_id);
      const found_product_Id = poid_object.purchaseOrderItems.find(
        (po) => po.POID === product_id.id
      ).product_Id;
      if (poid_object) {
        console.log("Found POID object:", poid_object);
        console.log("Found product_id:", found_product_Id);
        const particular_po_products = poid_object.purchaseOrderItems.filter(
          (po) => po.product_Id === found_product_Id
        );
        setpurchaseOrderItems(particular_po_products);
        console.log("particular", particular_po_products);
      } else {
        console.log(`POID ${po_id} not found at the purchase order level`);
      }
    };

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "400px",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      overflow: "scroll",
    };
    return (
      <div
        className={""}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <input
            ref={ref}
            style={{
              width: "70px",
              height: 30,
              marginBottom: "5px",
              marginRight: "5px",
              fontSize: "15px",
              textAlign: "center",
            }}
            type="number"
            min={0}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* <Button
            onClick={() => {
              props?.onadd(ref?.current?.value);
            }}
            size="small"
            variant="contained"
            style={{
              marginRight: "20px",
            }}
          >
            Add
          </Button> */}
          <Button
            style={{ marginTop: "2px", marginLeft: "auto", height: 30 }}
            size="small"
            variant="contained"
            onClick={() => {
              props?.onupdate(ref.current?.value);
            }}
          >
            Update
          </Button>
          <Button
            style={{ marginTop: "2px", marginLeft: "auto", height: 30 }}
            size="small"
            variant="contained"
            onClick={() => {
              handleOpen();

              getGrn(props?.myuserData, props?.itemDetails, props?.po_id);
            }}
          >
            Details
          </Button>
        </div>

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-title" variant="h6" component="h2">
                GRN Details
              </Typography>

              <Typography
                style={{ textAlign: "center", fontSize: 16 }}
                id="modal-title"
                variant="h6"
                component="h4"
              >
                {props?.myuserData.productname}
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="purchase order details">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>POID</TableCell> */}

                      <TableCell>Received QTY</TableCell>

                      {/* <TableCell>Status</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {purchaseOrderItems.map((item) => (
                      <TableRow key={item.POID}>
                        <TableCell>{item.POID}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.pricePerUnit}</TableCell>
                        <TableCell>{item.totalPrice}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>
                          {item.remarks !== "null" ? item.remarks : "N/A"}
                        </TableCell>
                      </TableRow>
                    ))} */}
                    {purchaseOrderItems.map((item, index) => {
                      const previousQuantity =
                        index === 0
                          ? item.quantity
                          : purchaseOrderItems[index - 1].quantity;
                      const rowStyle =
                        index === 0 ? { backgroundColor: "#f0f8ff" } : {};
                      return (
                        <TableRow key={item.POID} style={rowStyle}>
                          {/* <TableCell>{item.POID}</TableCell> */}
                          {/* <TableCell>{previousQuantity}</TableCell>{" "} */}
                          {/* Show previous quantity */}
                          <TableCell>
                            {index === 0
                              ? "Total Requested QTY" + ": " + previousQuantity
                              : previousQuantity - item.quantity}
                          </TableCell>{" "}
                          {/* Show current quantity */}
                          {/* <TableCell>{"applied"}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }
};
