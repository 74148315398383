import React, { Component } from "react";
import GlobalConfig from "../../Config";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import CustomProducts from "../ProductsModule/CustomProducts";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Store from "../../index";
import Loader from "../../MobileComponents/Loader";
import CustomSnackbar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { SKY_BLUE } from "../../colors/colors";
import { connect } from "react-redux";
import helpers from "../../UtilitiesFunctions";
import TextField from "@mui/material/TextField";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});

class MarkAsFulfilled extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openEditShipping: false,
      isSentShipmentDetails: false,
      tracking_number: null,
      customSnackbar: false,
      loader: false,
      snackbarMessage: "",
      snackbarType: "success",
      // shippingAddress: this.props.location.state.shippingAddress,
      products: this.props.location.state.products,
      order: this.props.location.state.order,
      lineItems: this.props.location.state.order.lineItems,
      snackbar: false,

      disableBtn: false,
    };
  }

  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  renderSnackbar = (message, type) => {
    this.setState({
      customSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };
  setSnackbarToFalse = () => {
    setTimeout(() => {
      this.setState({ customSnackbar: false });
    }, 3000);
  };
  handleChange = (event) => {
    //console.log(event.target.name);
    if (event.target.type === "checkbox") {
      this.setState({
        isSentShipmentDetails: event.target.checked,
      });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
    //console.log(this.state);
  };

  handleClickOpen = (name) => {
    if (name === "EditShipping") {
      //console.log(this.props);
      this.setState({ openEditShipping: true });
    }
  };
  handleClose = (name) => {
    if (name === "EditShipping") {
      this.setState({ openEditShipping: false });
    }
  };

  updateShippingAddress = (shippingAddress) => {
    //console.log(shippingAddress);
    this.setState({ shippingAddress: shippingAddress });
    this.handleClose("EditShipping");
  };

  updateFullfillStatus = (orderId) => {
    const array = this.state.order.lineItems;
    console.log("order fulfillment status:", this.state.order);
    // this.state.order.fulfilled = true;
    this.state.order.customerId = null;
    // if(this.state.order.paid){
    for (var i = 0; i < this.state.order.lineItems.length; i++) {
      array[i] = { ...array[i], fulfilled: true,unitOfMeasure
        :[
        {
            "POID": array[i].productId,
            "isDefault": true,
            "conversionFactor": 1,
            "id": "1",
            "name": "Nos",
            "relation": "null"
        }
    ] }
    }
    this.setState({
      ...this.state.order,
      fulfilled: true,
      order: { lineItems: array, fulfilled: true },
    });
    //console.log(this.state.order);
    this.setState({ loader: true });
    let url = this.props.global_config.basePath + `/orders/${orderId}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(this.state.order),
    }).then((response) => {
      fetch(this.props.global_config.basePath + `/orders/${orderId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response2) => response2.json())
        .then((data2) => {
          console.log(response);
          if (data2[0].paid === false) {
            Store.dispatch({
              type: "MARK_FULFILLED_UNPAID",
              orders: data2,
            });
          } else {
            Store.dispatch({
              type: "MARK_FULFILLED_PAID",
              orders: data2,
            });
            // helpers.toastNotify("success", "Order Successfully Updated!");
            this.setState({ loader: false });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/ViewOrders",
              });
            }, 4000);
          }
          // this.renderSnackbar("Order updated Successfully!", "success");
            helpers.toastNotify("success", "Order Successfully Updated!");
            this.setState({ loader: false });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/ViewOrders",
              });
            }, 4000);
        })
        .catch((e) => {
          console.log("in catch function", e.message);
          // helpers.toastNotify("error", "Something Went Wrong.");

          // this.renderSnackbar("Error! Something went wrong", "error");
          this.setState({ loader: false });
        });
    });
    // }else{
    //   helpers.toastNotify("error", "order need to be paid first");
    // }
  };
  render() {
    const { classes } = this.props;
    const { customSnackbar, snackbarMessage, snackbarType, loader } =
      this.state;
    if (customSnackbar) {
      //agar snackbar true hai to usay false karde wrna wo har render bar display hoga
      console.log("in snackbar condiiton");
      this.setSnackbarToFalse();
    }

    return (
      <div className={classes.root}>
        {loader ? (
          <Loader value="Updating Order.." />
        ) : (
          <>
            {this.state.snackbar ? (
              <>
                <Snackbar
                  open={this.state.snackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <this.Alert
                    severity="error"
                    color="error"
                    variant="filled"
                    onClose={this.closeSnack}
                  >
                    Please enter valid qunatity!
                  </this.Alert>
                </Snackbar>
              </>
            ) : null}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.header}>
                  <Box className={classes.box} p={1} width="100%">
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <ArrowBackIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.props.history.push("/ViewOrders");
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Box p={0.5} flexGrow={1} fontSize="20px">
                          Manual fulfillment
                        </Box>
                      </Grid>
                    </Grid>
                    <Button
                      size="small"
                      style={{ float: "right", textTransform: "none" }}
                    >
                      Print packing slip
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Paper className={classes.paper}>
                  <div style={{ backgroundColor: "white", marginTop: "10px" }}>
                    <Box bgcolor="">
                      #{this.props.location.state.order.orderId}&emsp;
                      {/* {this.state.shippingAddress.first_name +
                        " " +
                        this.state.shippingAddress.last_name} */}
                      &emsp;
                      {this.props.location.state.order.fulfilled
                        ? " Fulfilled"
                        : " Unfulfilled"}
                      <br />
                      {/* {this.state.shippingAddress.address} */}
                    </Box>
                    <div
                      style={{
                        display: "block",
                        //width: "90%",
                        //backgroundColor: "red",
                      }}
                    >
                      <Box>
                        <CustomProducts
                          products={this.props.location.state.order.lineItems}
                          isDelete={false}
                          disableQty={true}
                          isEdit={false}
                          isRefund={false}
                        />
                      </Box>
                    </div>
                    <hr />
                    <Box>
                      TRACKING INFORMATION (OPTIONAL)
                      <Box
                        display="flex"
                        flexDirection="row"
                        bgcolor="white"
                        margin="10px 0px 0px 10px"
                      >
                        <Box margin="0px 0px 0px 0px">
                          {/* <Box>Tracking number</Box> */}
                          <TextField
                            // style={{
                            //   width: "120%",
                            //   height: "50%",
                            // }}
                            label="Tracking number"
                            name="tracking_number"
                            type="number"
                            size="small"
                            onChange={this.handleChange}
                          />
                        </Box>
                        <Box margin="0px 50px 0px 20px">
                          {/* <Box marginLeft="55px">Shipping carrier</Box> */}
                          <TextField
                            // style={{
                            //   width: "95%",
                            //   height: "50%",
                            //   marginLeft: "55px",
                            // }}
                            label="Shipping carrier"
                            size="small"
                            name="last_name"
                            type="text"
                            //onChange={this.handleChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <hr />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper className={classes.paper}>
                  <Box p={1}>Summary</Box>
                  <hr />
                  <Button
                    style={{
                      backgroundColor: SKY_BLUE,
                      textTransform: "none",
                      color: "white",
                    }}
                    disabled={this.state.disableBtn}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      this.updateFullfillStatus(this.state.order.orderId);
                    }}
                  >
                    Fulfill Item
                  </Button>
                </Paper>
              </Grid>
            </Grid>
            {customSnackbar ? (
              <CustomSnackbar message={snackbarMessage} type={snackbarType} />
            ) : null}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(useStyles)(MarkAsFulfilled));
