import React, { Component, useRef } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GlobalConfig from "../../Config";
import TextField from "@mui/material/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Textarea } from "@mobiscroll/react-lite";
import Slide from "@material-ui/core/Slide";
import PurchaseOderModal from "../../components/OrderModals/PurchaseOrderModal";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CachedSharpIcon from "@mui/icons-material/CachedSharp";
import CancelIcon from "@material-ui/icons/Cancel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
// import "./App.css";
import Store from "../../index";
import { connect } from "react-redux";
import InventoryMobile from "../../MobileComponents/InventoryMobileComponents/InventoryMobile";
import Loader from "../../MobileComponents/Loader";
import { SKY_BLUE, MARS } from "../../colors/colors";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import DialogBox from "../../components/DailogBox";
import helpers from "../../UtilitiesFunctions";
import { grey } from "@material-ui/core/colors";
import { QrCode } from "@mui/icons-material";
import { Box } from "@material-ui/core";
import PurchaseProductsTable from "../../components/ProductsModule/PurchaseProductsTable";

const style = (theme) => ({
  inputHeight: {
    height: 1,
  },
  table: {
    width: "100%",
    margin: "50px auto",
    border: "1px solid rgba(246, 246, 247, 1)",
    borderRadius: "6px",
    boxShadow: "3px 3px 3px 3px #c4b9b9",
    padding: "10px",
  },
  [theme.breakpoints.only("xs")]: {
    marginTop: "0px",
    display: "flex",
  },
  TableButtons: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "0px",
    },
  },
  HeadingText: {
    [theme.breakpoints.only("xs")]: {},
  },
  Allignment: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1px",
    },
  },
  paper: {
    backgroundColor: "white",
    width: "700px",
    minHeight: "300px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backdrop: false,
      currentQuantity: 0,
      query: "",
      modal: false,
      products: [],
      filteredList: [],
      rows: [],
      transactionType: "",
      productSpecification_Id: "0",
      unitOfMeasureId: "",
      loading: true,
      previousInventories: [],
      snackbar: false,
      errorSnackbar: false,
      searchTerm: "",
      toggle: false,
      barcode: "",
      loaderMessage: "Loading Inventory..",
      dialogBox: false,
      dialogTitle: "",
      dialogMessage: "",
      type: "",
      index: 0,
      reason: "",
      quantityvalue: null,
      userdata: "",
      inventoryalertmodal: false,
    };
  }

  componentDidMount() {
    this.getProductsEveryTime();
    this.getProducts();
  }
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  handleChange = (e) => {
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousInventories,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_inventoryItems,
        e.target.value
      );
      this.setState({
        backdrop: false,
        products: filteredList,
      });
    }
  };
  cancelSearch = () => {
    this.setState({ query: "", products: this.state.previousInventories });
  };
  handleSearchByName = (key, value) => {
    this.setState({ backdrop: true });
    const filteredList = this.dynamicSearch(this.props.g_inventoryItems, value);
    if (filteredList.length !== 0) {
      this.setState({
        backdrop: false,
        products: filteredList,
      });
    } else {
      fetch(
        this.props.global_config.basePath + `/inventory/name?value=${value}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData, "after search querry");
          this.setState({
            backdrop: false,
            products: responseData,
          });
        })
        .catch((e) => {
          this.setState({
            backdrop: false,
          });
        });
    }
  };
  handleSearchByBarcode = (key, value) => {
    this.setState({ backdrop: true });
    fetch(
      this.props.global_config.basePath +
        `/inventory/barcodes/key=${key}&value=${value}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData, "after search querry");
        this.setState({
          backdrop: false,
          products: responseData,
        });
      })
      .catch((e) => {
        this.setState({
          backdrop: false,
        });
      });
  };

  getProductsEveryTime() {
    this.setState({ loading: true, products: [] });
    fetch(this.props.global_config.basePath + "/inventory", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.map((v) => {
          v.value = 0;
          v.selected = null;
        });
        Store.dispatch({
          type: "GET_INVENTORY_ITEMS",
          inventoryItems: data,
        });
        this.setState({
          products: data,
          loading: false,
          previousInventories: data,
        });
      })
      .catch((e) => {
        console.log(e);

        this.setState({ loading: false, products: [] });
      });
  }

  getProducts() {
    if (this.props.g_inventoryItems.length < 1) {
      this.setState({ loading: true, products: [] });
      fetch(this.props.global_config.basePath + "/inventory", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.map((v) => {
            v.value = 0;
            v.selected = null;
          });
          Store.dispatch({ type: "GET_INVENTORY_ITEMS", inventoryItems: data });
          this.setState({
            products: data,
            loading: false,
            previousInventories: data,
          });
        })
        .catch((e) => {
          console.log(e);

          this.setState({ loading: false, products: [] });
        });
    } else {
      this.setState({
        products: this.props.g_inventoryItems,
        loading: false,
        previousInventories: this.props.g_inventoryItems,
      });
    }
  }
  handleUpdateInventory = (product) => {
    const amount = product.value;
    console.log("here is updated:");
    this.setState({ transactionType: "Adjustment" });
    // this.setState({ transactionType: "Adjustment", Backdrop: true });

    const data = {
      ProductSpecification_Id: product.POID,
      balance: amount,
      transactionType: "adjustment",
      unitOfMeasureId: this.state.unitOfMeasureId,
      reason: this.state.reason,
    };

    fetch(
      this.props.global_config.basePath +
        "/inventory/adjustment/" +
        product.POID,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },

        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        const addedQuantity = parseInt(this.state.quantityvalue, 10) || 0; // Default to 0 if not a valid number
        const newQuantity = addedQuantity;

        const updatedProduct = {
          ...product.singleproduct,
          quantity: newQuantity,
        };

        console.log("Updated product:", updatedProduct);

        // Store.dispatch({
        //   type: "EDIT_INVENTORY_ITEM_QUANTITY",
        //   inventoryItems: product?.singleproduct,
        // });

        // Store.dispatch({
        //   type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
        //   updated_product: product?.singleproduct,
        // });

        Store.dispatch({
          type: "EDIT_INVENTORY_ITEM_QUANTITY",
          inventoryItems: updatedProduct,
        });

        Store.dispatch({
          type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
          updated_product: updatedProduct,
        });

        this.updateQuantity(product);
        console.log("check", product);

        await fetch(this.props.global_config.basePath + "/inventory", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.map((v) => {
              v.value = 0;
              v.selected = null;
            });
            Store.dispatch({
              type: "GET_INVENTORY_ITEMS",
              inventoryItems: data,
            });
            Store.dispatch({
              type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
              updated_product: product,
            });
            this.setState({
              products: data,
              loading: false,
              previousInventories: data,
            });
            this.updateQuantity("");
            this.setState({ backdrop: false, snackbar: false });
            helpers.toastNotify("success", "Inventory Successfully Updated!");
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false, products: [] });
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
      });
  };

  getDefaultUnitofMeasure = (product, event) => {
    this.setState({ backdrop: true });
    for (var i = 0; i < product.unitsOfMeasures.length; i++) {
      if (product.unitsOfMeasures[i].isDefault === true)
        this.setState(
          { unitOfMeasureId: product.unitsOfMeasures[i].POID },
          function () {
            this.handleAddInventory(product, event);
          }
        );
    }
  };
  getDefaultUnitofMeasureUpdate = (product, event) => {
    this.setState({ backdrop: true });
    for (var i = 0; i < product.unitsOfMeasures.length; i++) {
      if (product.unitsOfMeasures[i].isDefault === true)
        this.setState(
          { unitOfMeasureId: product.unitsOfMeasures[i].POID },
          function () {
            this.handleUpdateInventory(product, event);
          }
        );
    }
  };
  handleAddInventory = (product, event) => {
    this.state.currentQuantity = 0;
    const amount = product.value;
    if (this.state.transactionType === "ADD") {
      this.setState({ added: amount, removed: 0, backdrop: true });
    } else {
      this.setState({ removed: amount, added: 0 });
    }
    console.log("in handle submit");
    console.log("productID", product.POID);
    const data = {
      transactionType: this.state.transactionType,
      added: this.state.transactionType === "ADD" ? product.value : 0,
      removed: this.state.transactionType !== "ADD" ? product.value : 0,
      ProductSpecification_Id: product.POID,
      unitOfMeasureId: this.state.unitOfMeasureId,
      reason: this.state.reason,
    };
    console.log(data);
    console.log(JSON.stringify(data));
    fetch(this.props.global_config.basePath + "/inventory/" + product.POID, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        this.addQuantity(product);
        Store.dispatch({
          type: "EDIT_INVENTORY_ITEM_QUANTITY",
          inventoryItems: product,
        });

        Store.dispatch({
          type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
          updated_product: product,
        });

        await fetch(this.props.global_config.basePath + "/inventory", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.map((v) => {
              v.value = 0;
              v.selected = null;
            });
            Store.dispatch({
              type: "GET_INVENTORY_ITEMS",
              inventoryItems: data,
            });
            this.setState({
              products: data,
              loading: false,
              previousInventories: data,
            });
            this.addQuantity("");
            this.setState({ backdrop: false, snackbar: false });
            helpers.toastNotify("success", "Inventory Successfully Added!");
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false, products: [] });
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        console.log("here is add request from inventory", err);
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Could Not Add Inventory.");
      });
  };
  onAdd = (index) => {
    const products = [...this.state.products];
    products[index].selected = "ADD";
    this.setState({ products, transactionType: "ADD" });
  };
  onRemove = (index) => {
    const products = [...this.state.products];
    products[index].selected = "Remove";
    this.setState({ products, transactionType: "Remove" });
  };
  onAmountChange = (value, index) => {
    const products = [...this.state.products];
    products[index].value = Number(value);
    this.setState({ products });
  };
  onSelect = () => {
    this.setState({ selected: this.state.products.POID });
    console.log("selected ID", this.state.products.POID);
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  barcodeToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  addQuantity = (product) => {
    console.log("here is add product quantity", product);
    let inventories = this.state.products.filter((inventory) => {
      if (inventory.POID === product.POID) {
        inventory.quantity += product.value;
        inventory.value = 0;
        return inventory;
      }
      return inventory;
    });

    this.setState({ products: inventories });
  };
  updateQuantity = (product) => {
    let inventories = this.state.products.filter((inventory) => {
      if (inventory.POID === product.POID) {
        inventory.quantity = product.value;
        inventory.value = 0;
        return inventory;
      }
      return inventory;
    });

    this.setState({ products: inventories });
    console.log(inventories);
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false, errorSnackbar: false });
  };
  openDialogBox = () => {
    this.setState({ dialogBox: true });
  };
  openDialogForAddInventory = (index) => {
    this.setState({
      dialogTitle: "ADD INVENTORY",
      dialogMessage: "Provide Reason Why You want to add in inventory? ",
      type: "add",
      index,
    });
  };
  openDialogForUpdateInventory = (index) => {
    this.setState({
      dialogTitle: "UPDATE INVENTORY",
      dialogMessage: "Provide Reason Why You want to update inventory?",
      type: "update",
      index,
    });
  };
  setDialogBoxtoFalse = () => {
    this.setState({ dialogBox: false });
  };
  setDialogBoxtoOpen = (usedata) => {
    this.setState({ dialogBox: true, usedata: usedata });
  };
  handleAddOrUpdateInventory = (reason) => {
    const { type, index, products } = this.state;
    const product = products[index];

    this.setState({ reason: reason });
    console.log("selected product is: ", product);
    if (type === "update") {
      this.getDefaultUnitofMeasureUpdate(product);
    } else if (type === "add") {
      this.getDefaultUnitofMeasure(product);
    }
  };

  handleChangereason = (e) => {
    this.setState({ reason: e.target.value });
  };

  addProducutQuantity(product, reason) {
    // this.setState({ backdrop: true });

    const data = {
      transactionType: "ADD",
      added: this.state.quantityvalue,
      // removed: this.state.transactionType !== "ADD" ? this.state.quantityvalue : 0,
      ProductSpecification_Id: product?.poid,
      unitOfMeasureId: product?.unitOfMeasures[0]?.POID,
      reasonMessage: reason,
    };

    console.log("sending data", data);

    fetch(this.props.global_config.basePath + "/inventory/" + product?.poid, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const previousQuantity =
          parseInt(product.singleproduct.quantity, 10) || 0; // Default to 0 if undefined
        const addedQuantity = parseInt(this.state.quantityvalue, 10) || 0; // Default to 0 if not a valid number
        const newQuantity = previousQuantity + addedQuantity;

        const updatedProduct = {
          ...product.singleproduct,
          quantity: newQuantity,
        };

        console.log("Updated product:", updatedProduct);

        this.addQuantity(product.singleproduct);
        // Store.dispatch({
        //   type: "EDIT_INVENTORY_ITEM_QUANTITY",
        //   inventoryItems: product?.singleproduct,
        // });

        // Store.dispatch({
        //   type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
        //   updated_product: product?.singleproduct,
        // });

        Store.dispatch({
          type: "EDIT_INVENTORY_ITEM_QUANTITY",
          inventoryItems: updatedProduct,
        });

        Store.dispatch({
          type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
          updated_product: updatedProduct,
        });

        await fetch(this.props.global_config.basePath + "/inventory", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.map((v) => {
              v.value = 0;
              v.selected = null;
            });
            Store.dispatch({
              type: "GET_INVENTORY_ITEMS",
              inventoryItems: data,
            });
            this.setState({
              products: data,
              loading: false,
              previousInventories: data,
            });
            this.addQuantity("");
            this.setState({ backdrop: false, snackbar: false, loading: false });
            helpers.toastNotify("success", "Inventory Successfully Added!");
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false, products: [] });
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        console.log("here is add request from inventory", err);
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Could Not Add Inventory.");
      });
  }

  handleclosemodalalert() {
    this.setState({ inventoryalertmodal: false });
  }

  handleOpenmodalalert() {
    this.setState({ inventoryalertmodal: true });
  }

  scanBarCodeAndAddToCart = (barCode) => {

console.log("here is search bar code",barCode);

    const foundProducts = this.props.g_inventoryItems?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );
console.log("here is found products",foundProducts)
    if (foundProducts.length > 0) {
      this.setState({ products: foundProducts });
    } else if (foundProducts.length === 0) {
      helpers.toastNotify("error", "No products found with given barcode.");
    }
  };

  updateProductQuantity(product, reason) {
    console.log("product in update", product);
    const amount = product.value;
    // this.setState({ backdrop: true });

    const data = {
      ProductSpecification_Id: product.poid,
      balance: this.state.quantityvalue,
      transactionType: "adjustment",
      unitOfMeasureId: product.unitOfMeasures[0].POID,
      reasonMessage: reason,
    };

    fetch(
      this.props.global_config.basePath +
        "/inventory/adjustment/" +
        product.poid,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        // convert the React state to JSON and send it as the POST body
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        const addedQuantity = parseInt(this.state.quantityvalue, 10) || 0; // Default to 0 if not a valid number
        const newQuantity = addedQuantity;

        const updatedProduct = {
          ...product.singleproduct,
          quantity: newQuantity,
        };

        console.log("Updated product:", updatedProduct);

        // Store.dispatch({
        //   type: "EDIT_INVENTORY_ITEM_QUANTITY",
        //   inventoryItems: product?.singleproduct,
        // });

        // Store.dispatch({
        //   type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
        //   updated_product: product?.singleproduct,
        // });

        Store.dispatch({
          type: "EDIT_INVENTORY_ITEM_QUANTITY",
          inventoryItems: updatedProduct,
        });

        Store.dispatch({
          type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
          updated_product: updatedProduct,
        });

        // this.updateQuantity(product);
        console.log("check", product);

        await fetch(this.props.global_config.basePath + "/inventory", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            data.map((v) => {
              v.value = 0;
              v.selected = null;
            });
            Store.dispatch({
              type: "GET_INVENTORY_ITEMS",
              inventoryItems: data,
            });
            Store.dispatch({
              type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_WEB",
              updated_product: product.singleproduct,
            });
            this.setState({
              products: data,
              loading: false,
              previousInventories: data,
            });
            this.updateQuantity("");
            this.setState({ backdrop: false, snackbar: false, loading: false });
            helpers.toastNotify("success", "Inventory Successfully Updated!");
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false, products: [] });
          });
      })
      .catch((err) => {
        console.log("here is update inventory error", err);
        this.setState({ backdrop: false, errorSnackbar: false });
        // helpers.toastNotify("error", "Could Not Update Inventory.");
      });
  }

  handleupdateinventoryconfirmation(reason) {
    if (this.state.transactionType === "add") {
      this.addProducutQuantity(this.state.userdata, reason);
    } else if (this.state.transactionType === "adjustment") {
      this.updateProductQuantity(this.state.userdata, reason);
    }
  }

  render() {
    const { products, query, dialogBox, dialogMessage, dialogTitle } =
      this.state;
    const { classes } = this.props;
    console.log(
      "here is purchase orders",
      this.props.g_purchaseorders.purchaseorders
    );
    const columns = [
      { field: "id", headerName: "ID", minwidth: 100, maxWidth: 150 },

      {
        field: "name",
        headerName: "Product Name",
        minwidth: 300,
        flex: 1,
        headerAlign: "center",
        align: "center",
        valueFormatter: ({ value }) => {
          var varientproduct = " (";
          value?.productSpecCharacteristics?.map((productspec, index) => {
            productspec?.productSpecCharValues?.map(
              (productSpecCharValues, index) => {
                varientproduct +=
                  productSpecCharValues?.value +
                  (index + 1 == productspec?.productSpecCharValues?.length &&
                  productspec?.productSpecCharValues?.length > 1
                    ? ", "
                    : "");
              }
            );
            varientproduct +=
              index + 1 != value?.productSpecCharacteristics?.length &&
              value?.productSpecCharacteristics?.length > 1
                ? " - "
                : "";
          });
          varientproduct += ")";
          varientproduct = varientproduct === " ()" ? "" : varientproduct;

          return value.name + varientproduct;
        },

        renderCell: (params) => {
          var product = params.row.name;
          return (
            <div
              style={{
                lineHeight: 1,
                textAlign: "center",
                width: "100%",
                overflow: "auto",
                maxHeight: "130px",
                // msOverflowStyle: "hidden",
              }}
            >
              <h6 style={{}}>{product.name}</h6>

              {product?.productSpecCharacteristics?.length > 0 && (
                <div style={{}}>
                  <hr
                    style={{
                      height: "1%",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  />
                  {product?.productSpecCharacteristics?.map(
                    (productspec, index) => {
                      return (
                        <div style={{}}>
                          {productspec?.name + ":"}
                          {productspec?.productSpecCharValues?.map(
                            (productSpecCharValues, index) => {
                              return (
                                <div
                                  style={{
                                    display: "inline-block",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#D3D3D3",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    borderRadius: "30px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <b>{productSpecCharValues?.value}</b>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          );
        },
      },

      {
        field: "quantity",
        headerName: "Quantity",
        maxWidth: 150,
        flex: 1,
        headerAlign: "center",
        align: "center",
      },

      {
        headerName: "Action",
        field: "actions",
        minwidth: 150,
        flex: 1,
        headerAlign: "center",
        align: "center",
        // height:500,
        renderCell: (params, index) => {
          var userdata = params?.row?.actions;
          const onAdd = (value) => {
            this.setState({ quantityvalue: value }, () => {
              this.setState({
                dialogTitle: "ADD INVENTORY",
                dialogMessage:
                  "Provide Reason Why You want to add in inventory? ",
                transactionType: "add",
                userdata: userdata,
              });
              this.openDialogBox();
            });
          };

          const onUpdate = (value) => {
            this.setState({ quantityvalue: value }, () => {
              this.setState({
                dialogTitle: "UPDATE INVENTORY",
                dialogMessage:
                  "Provide Reason Why You want to update in inventory? ",
                transactionType: "adjustment",
                userdata: userdata,
              });
              this.openDialogBox();
            });
          };
          return (
            <InventoryComponent
              onadd={onAdd.bind(this)}
              onupdate={onUpdate.bind(this)}
            ></InventoryComponent>
          );
        },
      },
    ];
    const data = this.state;
    // const obj = this.props.g_inventoryItems;
    const obj = this.state.products;
    
    const rows = [];
    for (var i = 0; i < obj?.length; i++) {
      rows[i] = {
        id: obj[i].POID,
        name: {
          name: obj[i].name,
          productSpecCharacteristics: obj[i].productSpecCharacteristics,
        },
        // description: obj[i].description,
        quantity: obj[i].quantity,
        actions: {
          poid: obj[i].POID,
          unitOfMeasures: obj[i].unitsOfMeasures,
          singleproduct: obj[i],
        },
      };
    }
    function CustomToolbar() {
      return (
        <div style={{ width: "400px" }}>
          <GridToolbarContainer
            style={{
              backgroundColor: "#EFEFEF",
              border: "1px solid black",
              margin: "20px",
              width: "100px",
              height: "50px",
            }}
          >
            <GridToolbarExport
              csvOptions={{
                fileName: "inventoryDataBase",
                delimiter: ";",
                fields: ["id", "name", "quantity"],
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </div>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <Loader value={this.state.loaderMessage} />
        ) : (
          <>
            <Backdrop
              style={{
                zIndex: 1,
                color: "#fff",
              }}
              open={this.state.backdrop}
            >
              <CircularProgress thickness={2} size={90} color="inherit" />
            </Backdrop>
            {this.state.snackbar ? (
              <>
                <Snackbar
                  open={this.state.snackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="success"
                    variant="filled"
                    color="success"
                    onClose={this.closeSnack}
                  >
                    Inventory Updated{" "}
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            {this.state.errorSnackbar ? (
              <>
                <Snackbar
                  open={this.state.errorSnackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="error"
                    color="error"
                    variant="filled"
                    onClose={this.closeSnack}
                  >
                    Error! Request Failed
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            {dialogBox ? (
              <DialogBox
                title={dialogTitle}
                message={dialogMessage}
                // functiontocall={this.state.transactionType==="add"?this.addProducutQuantity:this.updateProductQuantity}
                userdata={this.state.userdata}
                transactionType={this.state.transactionType}
                confirmation={((reason) => {
                  this.handleupdateinventoryconfirmation(reason);
                }).bind(this)}
                cancelation={this.setDialogBoxtoFalse}
              />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <h5 className={classes.HeadingText}>Inventory</h5>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "5px" }}>
                    <PurchaseOderModal
                      // updateParent={this.updateMyCounter}
                      updateinvetory={this.getProductsEveryTime}
                      updateinventory1={this.getProducts}
                      onClickBtnClose={() =>
                        this.handleClose("browseProductsModal")
                      }
                    />
                  </div>

                  <div>
                    <Button
                      component={Link}
                      to="/"
                      style={{ backgroundColor: SKY_BLUE, color: "white" }}
                      variant="contained"
                      texttransform="none"
                    >
                      View Products
                    </Button>
                  </div>
                </div>
                {this.state.toggle ? (
                  <div style={{}}>
                    <BarcodeScannerComponent
                      width={250}
                      height={200}
                      onUpdate={(err, result) => {
                        if (result)
                          this.handleSearchByBarcode("barcode", result);
                        // else setData("Not Found");
                      }}
                    />
                    <p style={{ textAlign: "center" }}>{this.state.barcode}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginLeft: "20px", marginTop: "40px" }}>
                <TextField
                  fullWidth={true}
                  defaultValue={query}
                  name="query"
                  onChange={this.handleChange}
                  id="outlined-basic"
                  label="Search Inventory"
                  size="small"
                  style={{ fontSize: "5px", padding: "0px" }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ marginRight: "20px" }}>
                        {/* <IconButton> */}
                        <SearchTwoToneIcon />

                        {/* </IconButton> */}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        {this.state.query === "" ? null : (
                          <div
                            style={{ marginRight: "10px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.cancelSearch()}
                          >
                            <CancelIcon style={{ color: "red" }} />
                          </div>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "white",
                }}
              >
                <Box display="" p={1}>
                  <Box p={1}>
                    {/* <BrowseProducts
                      // updateParent={this.updateMyCounter}
                      onClickBtnClose={() =>
                        this.handleClose("browseProductsModal")
                      }
                    /> */}
                  </Box>
                </Box>
              </div>
              <Box
                pl={1}
                pr={1}
                // ml={1}
                // mr={1}
                style={{
                  // padding: "10px",
                  height: "38px",
                  border: "1px solid #dfe1e5",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  hoverBackgroundColor: "#eee",
                  color: "#212121",
                  fontSize: "16px",
                  fontFamily: "Arial",
                  iconColor: "grey",
                  lineColor: "rgb(232, 234, 237)",
                  placeholderColor: "grey",
                  display: "flex",
                  marginRight: "20px",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <QrCode fontSize="small" sx={{ color: grey[600] }} />
                </div>
                <div
                  style={{
                    flex: 1,
                    marginLeft: "10px",
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    placeholder="Scan/Enter Barcode"
                    style={{
                      height: "100%",
                      outline: "none",
                      border: "none",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.scanBarCodeAndAddToCart(e.target.value);
                        e.target.value = "";
                      }
                    }}
                  />
                </div>
              </Box>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "30px",
                backgroundColor: "white",
              }}
            >
              <DataGrid
                autoHeight={true}
                sortModel={[
                  {
                    field: "id",
                    sort: "desc",
                  },
                ]}
                rowHeight={130}
                headerHeight={40}
                // checkboxSelection={true}
                loading={this.state.loader}
                rows={rows}
                columns={columns}
                {...this.state.columns}
                slots={{ Toolbar: GridToolbar }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                onRowClick={(info) => {}}
              />
            </div>
            <div>
              <Dialog
                fullWidth={true}
                open={this.state.inventoryalertmodal}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleclosemodalalert}
              >
                <DialogTitle>{"Create New Category "}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        label="Category Description"
                        name="categoryDescription"
                        onChange={this.handleChangereason}
                      ></TextField>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleclosemodalalert}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={this.handleConfirmation} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        )}
      </>
    );
    // }
  }
}
const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    g_inventoryItems: state.inventoryState.inventoryItems,
    global_config: state.globalState,
    g_purchaseorders: state.purchaseOrderState.purchaseorders,
  };
};
export default connect(mapStateToProps, null)(withStyles(style)(Inventory));

const InventoryComponent = (props) => {
  {
    const ref = useRef();

    return (
      <div
        className={""}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <input
            ref={ref}
            style={{
              width: "155px",
              height: 40,
              marginBottom: "5px",

              fontSize: "15px",
              textAlign: "center",
            }}
            type="number"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={() => {
              props?.onadd(ref?.current?.value);
            }}
            size="small"
            variant="contained"
            style={{
              marginRight: "20px",
            }}
          >
            Add
          </Button>
          <Button
            style={{}}
            size="small"
            variant="contained"
            onClick={() => {
              props?.onupdate(ref.current?.value);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
};
