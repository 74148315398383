const initial_state = {
  orders: [],
  unfulfilled_orders: [],
  unpaid_orders: [],
  draft_orders: [],
  open_orders: [],
  close_orders: [],
};

const getOrders = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_ORDERS":
      console.log(action);
      return Object.assign({}, state, { orders: action.orders });
    case "APPEND_ORDERS":
      console.log(action);
      var appended_orders = state.orders;
      appended_orders.push(action.orders);
      return Object.assign({}, state, { orders: appended_orders });
    case "UNFULFILLED_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        unfulfilled_orders: action.unfulfilled_orders,
      });
    case "UNPAID_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        unpaid_orders: action.unpaid_orders,
      });
    case "DRAFT_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        draft_orders: action.draft_orders,
      });
    case "OPEN_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        open_orders: action.open_orders,
      });
    case "CLOSE_ORDERS":
      console.log(action);
      return Object.assign({}, state, {
        close_orders: action.close_orders,
      });
    case "MARK_FULFILLED_PAID": {
      let unfulfilledOrder;
      let openOrders;

      console.log("here is from store", action.orders);
      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_order[0].fulfilled = action.orders[0].fulfilled;
      unfulfilledOrder = state.unfulfilled_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );

      openOrders = state.open_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );

      return {
        ...state,
        unfulfilled_orders: unfulfilledOrder,
        open_orders: openOrders,
        close_orders: state.close_orders.concat(action.orders[0]),
      };
    }
    case "MARK_FULFILLED_UNPAID": {
      let selected_unpaidOrder;
      let openOrders;
      let unfulfilledOrder;

      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_order[0].fulfilled = action.orders[0].fulfilled;


      console.log("here is from on fullmed ", selected_order[0].fulfilled )
      // selected_unpaidOrder = state.unpaid_orders.filter(
      //   (order) => order.orderId === action.orders[0].orderId
      // );
      // selected_unpaidOrder[0].fulfilled = action.orders[0].fulfilled;

      // openOrders = state.open_orders.filter(
      //   (order) => order.orderId === action.orders[0].orderId
      // );
      // openOrders[0].fulfilled = action.orders[0].fulfilled;

      unfulfilledOrder = state.unfulfilled_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );

      return {
        ...state,
        unfulfilled_orders: unfulfilledOrder,
      };
    }
    case "POST_MARK_AS_PENDING_ORDERS":
      return {
        ...state,
        unfulfilled_orders: [
          ...state.unfulfilled_orders,
          action.unfulfilled_orders,
        ],
        orders: [...state.orders, action.unfulfilled_orders],
        open_orders: [...state.open_orders, action.unfulfilled_orders],
      };
    case "POST_MARK_AS_PAID_ORDERS":
      return {
        ...state,
        orders: [...state.orders, action.orders],
        unfulfilled_orders: [...state.unfulfilled_orders, action.orders],
        open_orders: [...state.open_orders, action.orders],
      };
    case "POST_MARK_AS_PAID__FULFILLED_ORDERS":
      return {
        ...state,
        orders: [...state.orders, action.orders],
        close_orders: [...state.close_orders, action.orders],
      };
    case "EDIT_ORDERS": {
      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_order[0].lineItems = action.orders[0].lineItems;

      let selected_unfulfilledOrder = state.unfulfilled_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (selected_unfulfilledOrder.length !== 0) {
        selected_unfulfilledOrder[0].lineItems = action.orders[0].lineItems;
      }
      let selected_openOrder = state.open_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (selected_openOrder.length !== 0) {
        selected_openOrder[0].lineItems = action.orders[0].lineItems;
      }
      let selected_unpaidOrder = state.unpaid_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (selected_unpaidOrder.length !== 0) {
        selected_unpaidOrder[0].lineItems = action.orders[0].lineItems;
      }

      return state;
    }
    case "RETURN_ORDER": {
      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_order[0].lineItems = action.orders[0]?.lineItems;

      let open_order = state.open_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (open_order.length !== 0) {
        open_order[0].lineItems = action.orders[0].lineItems;
      }
      let closed_order = state.close_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (closed_order.length !== 0) {
        closed_order[0].lineItems = action.orders[0].lineItems;
      }
      let unpaid_orders = state.unpaid_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      if (unpaid_orders.length !== 0) {
        unpaid_orders[0].lineItems = action.orders[0].lineItems;
      }

      return state;
    }
    case "MARK_AS_PAID_UNFULFILLED": {
      let selected_unpaidOrder;
      let openOrders;
      let unpaidOrders;

      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_order[0].paid = action.orders[0].paid;

      selected_unpaidOrder = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_unpaidOrder[0].paid = true;
      openOrders = state.open_orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      console.log("here is data after making it paid",openOrders)
      // commented for test 
      // openOrders[0]?.paid = true;

      unpaidOrders = state.unpaid_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );
      if (action.orders[0].fulfilled !== true) {
        let selected_unfulfilledOrder = state.unfulfilled_orders.filter(
          (order) => order.orderId === action.orders[0].orderId
        );
        // selected_unfulfilledOrder[0].paid = true;
      }
      return {
        ...state,
        unpaid_orders: unpaidOrders,
      };
    }

    case "MARK_AS_PAID_FULFILLED": {
      let selected_unpaidOrder;
      let openOrders;
      let unpaidOrders;

      let selected_order = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );

      console.log(selected_order);
      selected_order[0].paid = true;

      selected_unpaidOrder = state.orders.filter(
        (order) => order.orderId === action.orders[0].orderId
      );
      selected_unpaidOrder[0].paid = true;
      openOrders = state.open_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );

      unpaidOrders = state.unpaid_orders.filter(
        (order) => order.orderId !== action.orders[0].orderId
      );
      if (action.orders[0].fulfilled !== true) {
        let selected_unfulfilledOrder = state.unfulfilled_orders.filter(
          (order) => order.orderId === action.orders[0].orderId
        );
        selected_unfulfilledOrder[0].paid = true;
      }
      return {
        ...state,
        unpaid_orders: unpaidOrders,
        open_orders: openOrders,
        close_orders: state.close_orders.concat(action.orders[0]),
      };
    }
    case "CLEAR_ORDER_DATA":
      return initial_state;
    default:
      return state;
  }
};
export default getOrders;
